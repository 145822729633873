/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
import 'bootstrap';
import 'plyr/dist/plyr.css';
import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';

/*
|
| Importing App files
|----------------------
*/
import '@pages/main.js';
import '@pages/animations.js';
