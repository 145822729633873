/*** IMPORTS FROM imports-loader ***/
var define = false;

import $ from 'jquery';
import Plyr from 'plyr';

$(document).ready(function($) {

    // Vidéo edito
    const player = new Plyr('#player', {
    });


    // Owl carousel Logos customers - Homepage
    $(".owl-customers").owlCarousel({
        loop: true,
        dots: false,
        nav: false,
        autoHeight: false,
        margin:30,
        responsive:{
            0:{
                items:2.5,
                margin:15
            },
            600:{
                items:3.5,
                margin:15
            },
            1000:{
                items:4.5
            }
        }
    });

    // Owl carousel Logos customers - Homepage
    $(".owl-partners").owlCarousel({
        loop: false,
        dots: false,
        nav: false,
        margin:30,
        responsive:{
            0:{
                items:1.5,
                margin:15
            },
            600:{
                items:1.5,
                margin:15
            },
            1000:{
                items:3.5
            }
        }
    });


    /* Menu */
    $('nav ul > li').on('mouseenter', function(e) {
        e.preventDefault();
        $(this).find('ul').stop().slideDown();
    });
    $('nav ul > li').on('mouseleave', function(e) {
        e.preventDefault();
        $(this).find('ul').stop().slideUp();
    });

    /* Menu */
    $('.footer-mobile > li').on('click', function(e) {
        e.stopPropagation();
        $('.footer-mobile > li').find('ul').slideUp();
        $('.footer-mobile > li').not(this).removeClass('active');

        if ($(this).hasClass('active')) {
            $(this).find('ul').slideUp();
            $(this).removeClass('active');
        } else {
            $(this).find('ul').slideDown();
            $(this).addClass('active');
        }
    });

    $('.resources__choose-domain select').on('change', function() {
       let myValue = $(this).val();
       let url = new URL(window.location.href);

       if (url.searchParams.has("domain")) {
           url.searchParams.set("domain", myValue);
       } else {
           url.searchParams.append("domain", myValue);
       }

        window.location.href = url;
    });

    $('.news__choose-domain select').on('change', function() {
        let myValue = $(this).val();
        let url = new URL(window.location.href);

        if (url.searchParams.has("category")) {
            url.searchParams.set("category", myValue);
        } else {
            url.searchParams.append("category", myValue);
        }

        window.location.href = url;
    });


});
