/*** IMPORTS FROM imports-loader ***/
var define = false;

import Menu from '@components/menu.js';
import { TweenMax } from "gsap/TweenMax";
import $ from "jquery";

$(document).ready(function($) {

		const
			$body             = $('body'),
			scrollAreas       = document.querySelectorAll('.scroll-area'),
			$menuWrapper      = $('#mobile-menu'),
			$menuButton       = $('#header .btn-menu'),
			$menuLogo         = $('#header .logo a svg'),
			$menuBars         = $menuButton.find('.item-burger > span'),
			$menuClose        = $menuWrapper.find('.item-close'),
			$menuStaggerItems = $menuWrapper.find('[data-stagger-item]')
		;

		const menu = new Menu($menuWrapper, $menuButton, {
			reverseTimeScale: 2
		});

		menu.menuTimeline
			.to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
			.to($menuBars.eq(0), 0.5, { x: 0, y: 6, rotation: 45, ease: Power1.easeOut }, 'start')
			.to($menuBars.eq(2), 0.5, { x: 0, y: -6, rotation: -45, ease: Power1.easeOut }, 'start')
			//.to($menuButton, 0.5, { y: -60, ease: Power1.easeOut }, 'start')
			.from($menuWrapper, 0.5, { autoAlpha: 0, ease: Power1.easeOut }, 'start+=0.3')
			.staggerFrom($menuStaggerItems, 0.6, { autoAlpha: 0, x: -20, ease: Power1.easeOut }, '0.1', '+=0')
			.to($menuLogo, 1, {css: {'fill': '#fff'}}, {css: {'fill': '#002fbd'}})
			.to($body, 1, {css: {'overflow': 'hidden'}}, {css: {'overflow': 'visible'}})

		menu.init();

});
